import React, { useEffect, useState } from "react";
import Images from "../Images.json";
import { Container, Dialog, Grid, Slide } from "@mui/material";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Modalbox from "./Modalbox";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Animatronics = () => {
  const [modalOpen, setModalOpen] = useState(false); 
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const handleImageClick = (index) => {
   
    setSelectedImageIndex(index);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleNextClick = () => {
    setSelectedImageIndex((selectedImageIndex + 1) % Images[2].list.length);
  };

  const handlePrevClick = () => {
    setSelectedImageIndex(
      (selectedImageIndex - 1 + Images[2].list.length) % Images[2].list.length
    );
  };

  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [modalOpen]);

  return (
    <div className="animatronics-div" id="Gallery">
      <h2 className="hr-lines1" style={{ color: "darkgray" }}>
        Animatronics
      </h2>
      <Container>
        {/* <OwlCarousel autoplay loop margin={6} nav={false} dots={false}>
          {Images &&
            Images[2].list.map((image, index) => (
              <div
                key={index}
                className="item"
                onClick={() => handleImageClick(image.url, index)}
              >
                <img
                  src={image.url}
                  alt={`img-${index}`}
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    maxWidth: "400px",
                  }}
                />
              </div>
            ))}
        </OwlCarousel> */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          {Images &&
            Images[2].list.map((image, index) => (
              <Grid key={index} item sm={12} md={3} lg={3}>
                <div className="item">
                  <img
                    src={image.url}
                    alt={`img-${index}`}
                    onClick={() => handleImageClick(index)}
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      maxWidth: "400px",
                    }}
                  />
                </div>
              </Grid>
            ))}
        </Grid>
      </Container>

      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
      >
        <Modalbox Images={Images[2].list} selectedImageIndex={selectedImageIndex} setSelectedImageIndex={setSelectedImageIndex} handleCloseModal={handleCloseModal} i={0} />
      </Dialog>
    </div>
  );
};

export default Animatronics;
