import React from "react";



const Abouts = () => {
  return (
    <div className="abt-siv" id="About">
      <div className="abt-list ">
        {/* <h2 className='hr-lines1' style={{ color: 'darkgray', textAlign: 'center' }} id="Home">
          About Us
        </h2> */}
        <h4 style={{ fontFamily: 'yellowtail', color: "#0B60B0" , letterSpacing:'3px', fontSize: '2rem'}} >
          What it takes to design for animation,amusement and animatronics ?
        </h4>
        <div style={{ fontFamily: 'Tahomabold' }} className="list">
          <p>Extensive Field Work</p>
          <p>Hands on Experience</p>
          <p>Proficiency in Man and Material and Mechanism</p>
          <p>Commercial Acumen</p>
          <p>Market Awareness and Trend Analysis</p>
          <p>Creative Ingenuity with Practical Application</p>
          <h2 style={{ color: "#0B60B0", fontFamily: 'yellowtail', letterSpacing:'3px', fontSize: '2rem' }}>We have it...  We do it!</h2>
        </div>
      </div>

    </div>
  );
};

export default Abouts;
