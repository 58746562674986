import React from "react";
import { Container, Grid } from "@mui/material";

const Members = () => {
    return (
        <div className="name-div" id="Showreel">
            <h2 className='hr-lines1 mb-5' style={{ color: 'darkgray', textAlign: 'center' }} id="Home">
                Our Team
            </h2>
            <Container>
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                >
                    <Grid item sm={6} md={6} lg={4}>
                        <div className="about_data">
                            <div className="hover-crd">
                                <img src="/Assests/KUMARAVELAN.jpg" className=" about-img" alt="img" />
                                <div className="person">
                                    <p>KUMARAVELAN</p>
                                    <p>Advisor | Business Development</p>
                                </div>
                            </div>
                            <div className="person-description">
                                <p className="gaps">Kumaravelan has a Master’s degree in Business Administration (MBA) and a
                                    degree in Tourism Management.</p>
                                <p className="gaps">He is an accomplished professional with over 20 years of experience in the amusement
                                    park, theme park, and waterpark industry. </p>
                                <p className="gaps">He possesses proficiency in various areas including budgeting, business planning,
                                    operations management, and project management.</p>
                                <p className="gaps">With a strong background in sales, he has honed his skills to excel in his field.</p>

                                <p className="gaps">He has contributed his expertise to the development of renowned parks in India such as
                                    Ramoji Film City in Hyderabad and Imagica World in Mumbai, He has played a vital role in
                                    building the above-mentioned parks from scratch to completion.</p>
                                <p className="gaps">Currently, Kumaravelan holds the position of CEO at Aquasplash Water Park in Kinshasa, RD
                                    Congo. Africa. </p>
                                <p className="gaps">He oversees strategic direction, operations, and overall management of the park. </p>

                                <p className="gaps">This role underscores his leadership abilities and his capability to steer the project
                                    from the front in different verticals and in different complex environments and make
                                    them sail smoothly through rough industry conditions.</p>
                            </div>

                        </div>

                    </Grid>
                    <Grid item sm={6} md={6} lg={4}>
                        <div className="about_data">
                            <div className="hover-crd">
                                <img src="/Assests/KATHIRAVAN M.jpg" alt="img" className="img-fluid about-img" />
                                <div className="person">
                                    <p>KATHIRAVAN</p>
                                    <p>Director | Business Head</p>
                                </div>

                                <div className="person-description">
                                    <p className="gaps">Kathiravan has a fervent creative mind and skills. </p>
                                    <p className="gaps">He is an immensely experienced Fine Arts graduate with a major in Design and Visual
                                        Communication from the College of Fine Arts, University of Madras. </p>
                                    <p className="gaps">Over twenty-five years of extensive involvement in content development, pre-production,
                                        and production design across various entertainment sectors.</p>
                                    <p className="gaps">He has proven track record in art direction, production design, and project management
                                        within the feature film, animation, and leisure entertainment industries.</p>
                                    <p className="gaps">He managed design development and project management across international locations
                                        including Singapore, Thailand, Philippines, UAE and China for renowned leisure
                                        entertainment destinations. </p>
                                    <p className="gaps">He is proficient at conceptualizing, visualizing, designing, budgeting, scheduling, and
                                        executing projects within budget and schedule Para meters.</p>
                                    <p>He is known for his “Go getter” mind-set and “trouble shooter” capability.</p>

                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item sm={6} md={4} lg={4}>
                        <div className="about_data">
                            <div className="hover-crd">
                                <img src="/Assests/ROMMEL SEDDANO.jpg" className=" about-img" alt="img" />
                                <div className="person">
                                    <p>ROMMEL SADANNO</p>
                                    <p>Technical Director</p>
                                </div>
                            </div>
                            <div className="person-description">
                                <p className="gaps">Rommel Sedano holds a Technical Degree in Pneumatics & Hydraulics from Meralco Foundation
                                    Technical Institute and has pursued other technical courses. He also has completed
                                    technical degrees in Electricity and Electronics. Additionally, he has a Bachelor's
                                    Degree in Computer Science from the University of Saint Louis.</p>
                                <p className="gaps">Rommel Sedano is a seasoned professional with extensive experience in themed environment
                                    design, fabrication, and installation. He has a diverse skill set, including concept
                                    illustration, master planning, architectural renderings, scenic painting, faux finishes,
                                    and more. </p>
                                <p className="gaps">Rommel has also worked in branding, advertisement, and production design for various
                                    projects such as in store displays, events, and stage concepts. He has expertise in
                                    animatronic manufacture, mechanical and automation designs, and motion-based systems.
                                </p>
                                <p className="gaps">His recent role as Production Art Director at Universal Studios Beijing involved
                                    overseeing the creative direction of the Jurassic World Adventure Dark Ride, integrating
                                    various disciplines like animatronics, ride vehicles, and projection systems. Prior to
                                    this, he served as an Art & Technical Director at Silk roading (Beijing) Co. Ltd.,
                                    Themetech, and Thoughtful Themes Creations, Pvt. Ltd., where he contributed to numerous
                                    projects across China and India.</p>
                            </div>

                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Members;
