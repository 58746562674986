import './App.css';
import Navs from './components/Navs'
import Vdeos from './components/Vdeos'
import Abouts from './components/Abouts'
import Members from './components/Members'
import Animation from './components/Animation'
import Amusement from './components/Amusement'
import Animatronics from './components/Animatronics'
import Footers from './components/Footers'

function App() {
  return (
    <div>
      <Navs />
      <div className='margin-5'>
        <Vdeos />
        <Abouts />
        <Members />
        <Animation />
        <Amusement />
        <Animatronics />
        <Footers />
      </div>
    </div>
  );
}

export default App;
