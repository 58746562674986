import React, { useEffect, useState } from "react";
import Images from "../Images.json";
import { Container, Dialog, Grid, Slide } from "@mui/material";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Modalbox from "./Modalbox";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const Animation = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleNextClick = () => {
    setSelectedImageIndex((selectedImageIndex + 1) % Images[1].list.length);
  };

  const handlePrevClick = () => {
    setSelectedImageIndex(
      (selectedImageIndex - 1 + Images[1].list.length) % Images[1].list.length
    );
  };

  useEffect(() => {
    if (modalOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [modalOpen]);

  return (
    <div className="amusement-div" >
      <h2 className="hr-lines1" style={{ color: "darkgray" }}>
        Amusement
      </h2>
      <Container>
        {/* <OwlCarousel autoplay loop margin={6} nav={false} dots={false}>
          {Images &&
            Images[1].list.map((image, index) => (
              <div
                key={index}
                className="item"
                onClick={() => handleImageClick(image.url, index)}
              >
                <img
                  src={image.url}
                  alt={`img-${index}`}
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    maxWidth: "400px",
                  }}
                />
              </div>
            ))}
        </OwlCarousel> */}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
        >
          {Images &&
            Images[1].list.map((image, index) => (
              <Grid key={index} item sm={12} md={3} lg={3}>
                <div className="item"    >
                  <img
                    src={image.url}
                    alt={`img-${index}`}
                    onClick={() => handleImageClick(index)}
                    style={{
                      width: "100%",
                      cursor: "pointer",
                      maxWidth: "400px",
                    }}
                  />
                </div>
              </Grid>
            ))}
        </Grid>
      </Container>
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth="md"
      >
        <Modalbox Images={Images[1].list} selectedImageIndex={selectedImageIndex} setSelectedImageIndex={setSelectedImageIndex} handleCloseModal={handleCloseModal} i={0} />
      </Dialog>
      {/* <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <div className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              {Images &&
                Images[1].list.map((image, index) => (
                  <div
                    key={index}
                    className={`carousel-item ${selectedImageIndex === index ? "active" : ""
                      }`}
                  >
                    {selectedImageIndex === index && (
                      <img
                        src={image.url}
                        className="d-block w-100"
                        alt={`img-${index}`}
                      />
                    )}
                  </div>
                ))}
            </div>
            <button className="carousel-control-prev" onClick={handlePrevClick}>
              <FaChevronLeft />
            </button>
            <button className="carousel-control-next" onClick={handleNextClick}>
              <FaChevronRight />
            </button>
          </div>
          <button className="close-carousel" onClick={handleCloseModal}>
            <IoCloseCircleOutline />
          </button>
        </Box>
      </Modal> */}
    </div>
  );
};

export default Animation;
